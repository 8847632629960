/* IMPORT FONTS */

@font-face {
  font-family: 'Montserrat';
  font-weight: 500;
  src: url(/dist/Montserrat-Regular-webfont-043bb021d7d6d81c4e1c6c4c91e69068.eot);
  src: url(/dist/Montserrat-Regular-webfont-043bb021d7d6d81c4e1c6c4c91e69068.eot?#iefix)
      format('embedded-opentype'),
    url(/dist/Montserrat-Regular-webfont-a9d2983f36736cb4b2fe842eb3513c11.woff2)
      format('woff2'),
    url(/dist/Montserrat-Regular-webfont-801539e3398a5e0a863f6334da44ce9c.woff)
      format('woff'),
    url(/dist/Montserrat-Regular-webfont-88934bd948e84a42031c80a138b6a458.ttf)
      format('truetype'),
    url(/dist/Montserrat-Regular-webfont-c6d64ae99fd2cc40816d0dffef070f31.svg#montserratregular)
      format('svg');
}

@font-face {
  font-family: 'Montserrat';
  font-weight: 400;
  src: url(/dist/Montserrat-Light-webfont-1207fda0471381030eea7d566c8907c7.eot);
  src: url(/dist/Montserrat-Light-webfont-1207fda0471381030eea7d566c8907c7.eot?#iefix)
      format('embedded-opentype'),
    url(/dist/Montserrat-Light-webfont-645a81f35410ce162dfa68a196044d21.woff2)
      format('woff2'),
    url(/dist/Montserrat-Light-webfont-4adf85c2d441e2483ea3ab4c5603ce17.woff)
      format('woff'),
    url(/dist/Montserrat-Light-webfont-ef0e3b4eb25b3b6ed1009d7164a40cac.ttf)
      format('truetype'),
    url(/dist/Montserrat-Light-webfont-126e7deed1799068aecd2b5055dc803c.svg#montserratlight)
      format('svg');
}

@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-Thin-35b7cf4cc47ac526b745c7c29d885f60.ttf)
    format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-ExtraLight-909744bbb5a7ede41ce522a1507e952c.ttf)
    format('truetype');
  font-weight: 200;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-Light-6ffbefc66468b90d7af1cbe1e9f13430.ttf)
    format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-Regular-515cae74eee4925d56e6ac70c25fc0f6.ttf)
    format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-Medium-5ff1f2a9a78730d7d0c309320ff3c9c7.ttf)
    format('truetype');
  font-weight: 500;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-SemiBold-ec60b23f3405050f546f4765a9e90fec.ttf)
    format('truetype');
  font-weight: 600;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-Bold-91e5aee8f44952c0c14475c910c89bb8.ttf)
    format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Inter';
  src: url(/dist/Inter-ExtraBold-bd9525f1099e9f5845f6aef2956e9fb8.ttf)
    format('truetype');
  font-weight: 800;
}
